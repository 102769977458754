/* eslint-disable react-hooks/rules-of-hooks */
import './ReportRunConfigWizard.scss';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import { TabWizard, AccordionWizard, TabWizardRef, ByzzerChangeEventHandler } from '@byzzer/ui-components';
import ReportRunConfigSummaryStep from '../ReportRunConfigSummaryStep/ReportRunConfigSummaryStep';
import {
    ConfigOptionsType,
    MarketRunConfigOptions,
    ProductRunConfigOptions,
    RunConfigOptions,
} from '@/types/RunConfigOptions';
import {
    DimensionRunConfigFiltersStep,
    MarketRunConfigFiltersStep,
    ProductRunConfigFiltersStep,
    SegmentRunConfigFiltersStep,
    TimePeriodRunConfigFiltersStep,
} from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigFilters';
import { CustomTimePeriod, RelativeTimePeriod, ReportRunConfig } from '@/types/ReportRun';
import {
    ReportRunConfigWizardContext,
    ReportRunConfigWizardContextValue,
} from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext';
import { useTenantApi } from '@/hooks/useTenantApi';
import { useUser } from '@/contexts/UserContext';
import { isMarketDisabled, useMarketService } from '@/services/market.service';
import { useLocation } from 'react-router-dom';
import { MarketPickerContext } from '@/components/MarketPicker';
import ScheduleReportStep from '../ScheduleReportStep/ScheduleReportStep';

export type ReportRunConfigWizardProps = {
    className?: string;
    runType?: RunType;
    sku?: string;
    runConfigOptions?: RunConfigOptions[];
    defaultValues?: Partial<ReportRunConfig>;
    onComplete?: (runConfig: ReportRunConfig) => void;
    busy?: boolean;
    busyCompleteButtonText?: string;
    enableScheduleReport: boolean;
    onEnableScheduleReport?: () => void;
    onCreateSchedule: (runConfig: ReportRunConfig, schedule: Schedule) => void;
    onRescheduleReport?: (schedule: Schedule) => void;
    onScheduleDataChange: ByzzerChangeEventHandler<Schedule>;
    scheduleData: Schedule;
    initialSchedule?: Schedule;
    mode?: ScheduleModifyMode;
    onUpdateScheduleReportConfig?: (runConfig: ReportRunConfig) => void;
    scheduledRunConfig?: ReportRunConfig;
    navigateStepIndex?: number;
};

const baseClassName = 'report-run-config-wizard';

const FILTER_BY_OPTION_TYPE: Partial<Record<ConfigOptionsType, any>> = {
    product: ProductRunConfigFiltersStep,
    market: MarketRunConfigFiltersStep,
    submarket: MarketRunConfigFiltersStep,
    dimension: DimensionRunConfigFiltersStep,
    time_period: TimePeriodRunConfigFiltersStep,
    shopper_segment: SegmentRunConfigFiltersStep,
};

export function ReportRunConfigWizard({
    className,
    runType,
    sku,
    runConfigOptions,
    defaultValues,
    enableScheduleReport,
    onComplete,
    onEnableScheduleReport,
    busy,
    busyCompleteButtonText,
    scheduleData,
    onScheduleDataChange,
    onCreateSchedule,
    onRescheduleReport,
    mode,
    initialSchedule,
    scheduledRunConfig,
    onUpdateScheduleReportConfig,
    navigateStepIndex
}: ReportRunConfigWizardProps) {
    if (!runConfigOptions) return null;
    const { clearMarketCache, getCachedMarketNodeByName } = useMarketService();
    const { state }: any = useLocation();
    const { requiredMasterCompany, requiredMarketGroup, requireRemainingMarket } = useContext(MarketPickerContext);
    const {
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
        company,
    } = useUser();
    const [contextValue, setContextValue, contextRef] = useState<ReportRunConfigWizardContextValue>({
        value: {
            brands: [],
            categories: [],
            characteristics: [],
            datatype: 'rms',
            focusBrands: [],
            omniFocusProducts: [],
            omniProductLevel: '', // temp workaround for BYZ-7884, where Omni product level shows up on summary screen for all reports.  Removed 'brand' from default value in wizard and set it here when loading.  fix belongs in summary ultimately.
            markets: [],
            subMarkets: [],
            productDimensions: [],
            reportDimension: 'products',
            subcategories: [],
            themeSku: '',
            timePeriod: { type: 'relative' },
            categorySelectionAggregationLevel: 'category',
            aggregationLevel: 'category',
            demographicDimensions: [],
            demographics: [], // [{key:'',operation:'is',value:[]}],
            themeAttribute: [],
            salesThresholds: [],
            growthThresholds: [],

            attributes: [],
            attributeGroup: [],
            attributesCustomCodes: [],
        },
        runType,
        sku,
        onChange(name: keyof ReportRunConfig, value: any): void {
            // do not inline this logic.
            const shouldResetMarkets = name === 'categories' && contextRef.current.value.datatype !== 'cps'; // don't reset for CPS, BYZ-8162;

            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    markets: shouldResetMarkets ? [] : current.value.markets,
                    subMarkets: shouldResetMarkets ? [] : current.value.subMarkets,
                    [name]: value,
                },
            }));
        },
    });
    const isEditScheduleMode: boolean = mode === 'editSchedule' || mode === 'editScheduleSeries';

    useEffect(() => {
        const timePeriodType = contextRef.current.value.datatype === 'omni' ? 'omni' : contextRef.current.value.datatype === 'osh' ? 'osh' : 'relative';

        if (!contextRef.current.value?.timePeriod?.period) {
            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    timePeriod: { type: timePeriodType },
                },
            }));
        }
    }, [contextRef.current.value.datatype]); //TODO: to remove this altogether and set the tp type in the useEffect method defined below for setting timeperiod

    useEffect(() => {
        // clean up the market cache when the wizard unmounts
        return clearMarketCache;
    }, []);

    useEffect(() => {
        const productOptions = runConfigOptions.find(
            (runConfigOption) => runConfigOption.type === 'product'
        ) as ProductRunConfigOptions;
        const marketOptions = runConfigOptions.find(
            (runConfigOption) => runConfigOption.type === 'market'
        ) as MarketRunConfigOptions;

        const hasDefaultAndMaxFocusBrands =
            Boolean(defaultValues?.focusBrands?.length) && Boolean(productOptions?.maxFocusBrands);
        const hasDefaultAndMaxCategories =
            Boolean(defaultValues?.focusBrands?.length) && Boolean(productOptions?.maxCategories);
        const hasDefaultAndMaxCharacteristics =
            Boolean(defaultValues?.focusBrands?.length) && Boolean(productOptions?.maxCharacteristics);
        const hasDefaultAndMaxMarkets =
            Boolean(defaultValues?.focusBrands?.length) && Boolean(marketOptions?.maxMarkets);

        // Ensuring the max categories/markets etc isn't exceeded if user defaults contains a number greater than the max.  If they are greater, set to blank.  This list of fields could grow, covering the common ones for now. BYZ-8395
        const adjDefaultFocusBrands =
            hasDefaultAndMaxFocusBrands &&
            Number(defaultValues?.focusBrands?.length) > Number(productOptions?.maxFocusBrands)
                ? []
                : defaultValues?.focusBrands ?? [];
        const adjDefaultCategories =
            hasDefaultAndMaxCategories &&
            Number(defaultValues?.categories?.length) > Number(productOptions?.maxCategories)
                ? []
                : defaultValues?.categories ?? [];
        const adjDefaultCharacteristics =
            hasDefaultAndMaxCharacteristics &&
            Number(defaultValues?.characteristics?.length) > Number(productOptions?.maxCharacteristics)
                ? []
                : defaultValues?.characteristics ?? [];
        const adjDefaultMarkets =
            hasDefaultAndMaxMarkets && Number(defaultValues?.markets?.length) > Number(marketOptions?.maxMarkets)
                ? []
                : defaultValues?.markets ?? [];

        setContextValue((current) => ({
            ...current,
            value: {
                ...current.value,
                ...defaultValues,
                focusBrands: adjDefaultFocusBrands,
                categories: adjDefaultCategories,
                characteristics: adjDefaultCharacteristics,
                markets: adjDefaultMarkets,
            },
        }));
    }, [defaultValues, runConfigOptions]);

    //navigate to the last tab always if runWithLatestDate button is clicked in the ReportHistory page, else navigate to the first tab
    useEffect(() => {
        wizardRef.current!.activeStepIndex = state?.navigateTabIndex || navigateStepIndex || 0;
    }, [state?.navigateTabIndex, navigateStepIndex]);

    useEffect(() => {
        switch (mode) {
            case 'copySchedule':
                wizardRef.current!.enableStep('product');
                wizardRef.current!.enableStep('market');
                wizardRef.current!.enableStep('submarket');
                wizardRef.current!.enableStep('dimension');
                wizardRef.current!.enableStep('time_period');
                wizardRef.current!.enableStep('shopper_segment');
                
                wizardRef.current!.enableStep('review_and_run');
                wizardRef.current!.activeStep = 'review_and_run';
                break;
            case 'editSchedule':
                wizardRef.current!.enableStep('schedule_report');
                wizardRef.current!.activeStep = 'schedule_report';
                break;
            case 'editScheduleSeries':
                wizardRef.current!.enableStep('market');
                wizardRef.current!.enableStep('submarket');
                wizardRef.current!.enableStep('dimension');
                wizardRef.current!.enableStep('time_period');
                wizardRef.current!.enableStep('shopper_segment');
                wizardRef.current!.enableStep('review_and_run');
                
                wizardRef.current!.enableStep('product');
                wizardRef.current!.activeStep = 'product';
                break;
        }
    }, [mode]);

    useEffect(() => {
        if (contextRef.current.value.timePeriod?.type && state) {
            const contextValue = contextRef.current.value;
            const relativeTime = contextValue.timePeriod as RelativeTimePeriod;
            const customTime = contextValue.timePeriod as CustomTimePeriod;

            const customTimePeriod = customTime?.period;
            const customTimePeriodEndDate = customTimePeriod?.endDate;

            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    timePeriod: customTimePeriodEndDate
                        ? {
                              ...customTime,
                              period: {
                                  ...customTimePeriod,
                                  endDate:
                                      state?.navigateTabIndex && state?.reportRunByLatestDate
                                          ? (state.reportRunByLatestDate as Date)
                                          : customTimePeriodEndDate,
                              },
                          }
                        : relativeTime,
                },
            }));
        }
    }, [contextRef.current.value.timePeriod?.type, state]);

    useEffect(() => {
        const selectedCategories = contextRef.current.value.categories;
        const reportType = contextRef.current.value.datatype;

        if (contextRef.current.value?.markets !== undefined) {
            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    markets: current.value.markets?.map((item) => ({
                        ...item,
                        disabledMarketNode: isMarketDisabled(
                            getCachedMarketNodeByName(item.name, selectedCategories)!,
                            {
                                requiredMarketGroup,
                                requiredMasterCompany,
                                requireRemainingMarket,
                                enableLimitedMarketSubscription,
                                accessibleMasterCompanies,
                                reportType: reportType,
                                purchasedMarketKeys: company?.purchasedMarketKeys,
                            }
                        ),
                    })),
                },
            }));
        }
    }, [defaultValues?.categories, contextRef.current.value.categories, contextRef.current.value.datatype]);

    const handleComplete = useCallback(async () => {
        const value = contextRef.current.value;
        if (!value) return;

        onComplete?.(value);
    }, [contextValue]);

    const Wizard = window.localStorage['wizard-mode'] === 'accordion' ? AccordionWizard : TabWizard;
    const wizardRef = useRef<TabWizardRef | null>(null);

    function goToScheduleStep() {
        // Activating the schedule step
        wizardRef.current!.enableStep('schedule_report');
        wizardRef.current!.activeStep = 'schedule_report';
        onEnableScheduleReport?.();
    }

    const handleCreateSchedule = (scheduleDataInput: Schedule) => {
        const value = contextRef.current.value;
        if (!value) return;

        onCreateSchedule?.(value, scheduleDataInput);
    };

    const handleModifySchedule = (scheduleDataInput?: Schedule) => {
        const value = contextRef.current.value;
        if (!value) return;

        switch (mode) {
            case 'editScheduleSeries':
                onUpdateScheduleReportConfig?.(value);
                break;

            case 'editSchedule':
                onRescheduleReport?.(scheduleDataInput!);
                break;
        }
    };

    const shouldExcludeStep = (stepName: ConfigOptionsType): boolean => {
        return (mode === 'editSchedule' && stepName !== 'schedule_report') ||
               (stepName === 'schedule_report' && !enableScheduleReport);
    };

    const steps = useMemo(() => {
        return runConfigOptions.map((options, i) => {
            const Step = FILTER_BY_OPTION_TYPE[options.type];
            return (
                <Step
                    key={`${options.type}${i}`}
                    id={options.type}
                    exclude={shouldExcludeStep(options.type)}
                    {...options}
                />
            ); // WithUid={`${options.type}`} , to check why this attribute throwing warnings
        });
    }, [runConfigOptions]);

    return (
        <ReportRunConfigWizardContext.Provider value={contextValue}>
            <Wizard
                className={classnames(baseClassName, className)}
                ref={wizardRef}
                disableInactiveStepRendering={true}
            >
                {steps}
                <ReportRunConfigSummaryStep
                    id={'review_and_run'}
                    onNext={isEditScheduleMode ? handleModifySchedule : handleComplete}
                    runConfigOptions={runConfigOptions}
                    busy={busy}
                    busyNextText={busyCompleteButtonText}
                    sku={sku}
                    exclude={shouldExcludeStep('review_and_run')}
                    isEditScheduleMode={isEditScheduleMode}
                    scheduledRunConfig={scheduledRunConfig}
                    onEnableScheduleReport={goToScheduleStep}
                />
                <ScheduleReportStep
                    id={'schedule_report'}
                    enable={enableScheduleReport}
                    exclude={shouldExcludeStep('schedule_report')}
                    onNext={isEditScheduleMode ? handleModifySchedule : handleCreateSchedule}
                    value={scheduleData}
                    initialSchedule={initialSchedule}
                    onChange={onScheduleDataChange}
                    isEditScheduleMode={isEditScheduleMode}
                />
            </Wizard>
        </ReportRunConfigWizardContext.Provider>
    );
}

export default ReportRunConfigWizard;
